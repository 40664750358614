rodijco.controller 'SettingsChangePasswordController', [
  '$scope'
  'httpService'
  '$location'
  (scope, httpService, $location) ->

    scope.message = null
    scope.changePasswordData = {}

    scope.changePassword = (data) ->
      httpService.post 'user/change-password', data, (response) ->
        if response.success
          scope.changePasswordData = {}
          $location.path('/settings')
        else
          scope.message = response.data?.message
]
